<template>
  <div class="container mx-auto mb-1 mt-1">
    <b-button
      class="mb-1"
      variant="primary"
      @click="$router.go(-1)"
    >
      Go Back
    </b-button>
    <b-card
      v-if="!video_active"
      class=""
    >
      <div class="container">
        <img
          src="https://ak.picdn.net/shutterstock/videos/1019308261/thumb/1.jpg"
          alt="user-profile-cover"
          class="responsive blur-1"
        >
        <div class="card-overlay text-white">
          <div class="d-flex flex-col justify-between h-full">
            <div class="text-center mt-8 w-full">
              <p class="text-white mb-2 tracking-wide">
                The training video will be available on March 27th. Stay tuned!
              </p>
            </div>
            <div class="text-center w-full" />
          </div>
        </div>
      </div>
    </b-card>
    <div
      v-else
      class="vx-row md:w-full"
    >
      <video-player
        ref="videoPlayer"
        :playsinline="true"
        class="card-video"
        :options="playerOptions"
        @ready="playerReadied"
      />
    </div>
  </div>
</template>
<script>
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      video_active: true,
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0, 2.5, 3.0],
        sources: [
          {
            type: 'video/quicktime',
            src: `${this.$bucketUrl}/media/interviewee.mov`,
          },
        ],
        // poster: "https://goo.gl/xcCsDd",
      },
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  created() {
    const { user_group } = this.$store.state.auth.ActiveUser
    if (user_group.includes(this.$UserGroups.ADMIN)) {
      this.playerOptions.sources = [
        {
          type: 'video/mp4',
          src: `${this.$bucketUrl}/media/interviewer.mov`,
        },
      ]
    } else {
      this.playerOptions.sources = [
        {
          type: 'video/quicktime',
          src: `${this.$bucketUrl}/media/interviewee.mov`,
        },
      ]
    }
  },
  methods: {
    playerReadied() {
      //
    },
  },
}
</script>

<style lang="scss">
.card-video {
  .video-js {
    height: 800px;
    width: 1200px;
  }
}
</style>
